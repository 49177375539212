import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { useForm } from 'react-hook-form';
import {
  userSelector,
  isSignedInSelector,
  selectSubscriptionLoading
} from '../redux/selectors';
import { submitSubscription } from '../redux/actions';
import { User } from '../redux/prop-types';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { Spacer } from '../components/helpers';
import {
  formatCpfValue,
  formatPhoneValue,
  isCPFValid
} from '../utils/data-complement/validate-inputs';
import createRedirect from '../components/create-redirect';
import './data-complement.css';

type DataComplementProps = {
  readonly isSignedIn: boolean;
  readonly user: User;
  readonly submitSubscription: (data: FormValues) => void;
  readonly subscriptionLoading: boolean;
};

type FormValues = {
  name: string;
  cpf: string;
  phone: string;
};

const mapStateToProps = createSelector(
  userSelector,
  isSignedInSelector,
  selectSubscriptionLoading,
  (user: User, isSignedIn: boolean, subscriptionLoading: boolean) => ({
    user,
    isSignedIn,
    subscriptionLoading
  })
);

const mapDispatchToProps = {
  submitSubscription
};

const RedirectLearn = createRedirect('/learn');
const RedirectIndex = createRedirect('/');

function DataComplement({
  isSignedIn,
  user,
  submitSubscription,
  subscriptionLoading
}: DataComplementProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      cpf: '',
      phone: ''
    }
  });

  const onSubmit = (data: FormValues) => {
    submitSubscription(data);
  };

  if (!isSignedIn) {
    return <RedirectIndex />;
  }

  if (isSignedIn && user.subscription) {
    return <RedirectLearn />;
  }

  return (
    <>
      <Helmet title={`${t('register.title')} | DEVstart`} />
      <Grid>
        <Spacer size='large' />
        <div className='complement-container'>
          <div className='form-complement-container'>
            <p id='data-complement-title'>{t('data-complement.title')}</p>
            <Spacer size='small' />
            <p id='data-complement-subtitle'>{t('data-complement.subtitle')}</p>
            <Spacer size='medium' />
            <form onSubmit={handleSubmit(onSubmit) as () => void}>
              <Input
                {...register('name', {
                  required: 'Nome completo é obrigatório'
                })}
                placeholder='Nome completo'
                hasError={!!errors.name}
                messageError={errors.name?.message}
              />
              <Input
                {...register('phone', {
                  validate: value =>
                    formatPhoneValue(value) ||
                    'Verifique o número de telefone e tente novamente.'
                })}
                hasMask
                maskValue='(99) 99999-9999'
                placeholder='Telefone'
                hasError={!!errors.phone}
                messageError={errors.phone?.message}
              />
              <Input
                {...register('cpf', {
                  validate: value =>
                    (formatCpfValue(value) && isCPFValid(value)) ||
                    'Verifique o número do CPF e tente novamente.'
                })}
                hasMask
                maskValue='999.999.999-99'
                placeholder='CPF'
                hasError={!!errors.cpf}
                messageError={errors.cpf?.message}
              />
              <Spacer size='medium' />
              <Button
                type='submit'
                buttonType={ButtonTypes.Primary}
                style={{ height: '48px', width: '100%' }}
                isLoading={subscriptionLoading}
              >
                {t('data-complement.finish-complement')}
              </Button>
              <Spacer size='medium' />
              <p id='info-terms'>
                {t('register.headings.sign-up-accept-terms')}
              </p>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DataComplement);
